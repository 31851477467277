<template>

  <div>
    <banner />
    <div class="section-title pb-4">
      <h2 class="mt-5">Qu'est-ce qu'un CEV</h2>
    </div>
    <!-- <fret-tables /> -->
    <!-- <actualite /> -->
  </div>

</template>

<script>
// import FeatureSection from '@/layouts/SiteLayout/home/FeatureSection.vue'
// import TransportSection from '@/layouts/SiteLayout/home/TransportSection.vue'
// import About from '@/layouts/SiteLayout/home/About.vue'
import Actualite from '@/layouts/SiteLayout/home/Actualite.vue'
import Banner from '@/layouts/SiteLayout/home/Banner.vue'

export default {
  name: 'Home',
  components: {
    Banner,
    Actualite,
    FretTables,
  },

}
</script>
